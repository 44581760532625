import React from 'react'
import { useTranslation } from 'react-i18next'
import Avatar from 'react-avatar'

export const UserMenuLinkProfileLabel = ({ user }) => {
  const { t } = useTranslation('common')

  const avatar = (
    <Avatar
      round
      fgColor={'white'}
      color={'black'}
      src={user.getAvatar(50)}
      name={user.getFullName()}
      size="23"
    />
  )

  return (
    <div className="flex items-center justify-between w-full">
      <span>{t('navbar.userMenu.profile')}</span>
      {avatar}
    </div>
  )
}
