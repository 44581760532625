import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@szhsin/react-menu'

const UserMenuLink = ({ onClick, label }) => {
  const menuItemClassName = () =>
    'px-6 rounded-xl py-3 text-sm text-gray-800 hover:bg-gray-100 hover:text-black'

  return (
    <MenuItem className={menuItemClassName} onClick={onClick}>
      {label}
    </MenuItem>
  )
}

UserMenuLink.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.any,
}

export default UserMenuLink
