import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Avatar from 'react-avatar'
import { useTranslation } from 'react-i18next'

const UserMenuButton = forwardRef(({ user, onlyAvatar, isWhite, ...rest }, ref) => {
  const { t } = useTranslation('common')

  return (
    <button
      ref={ref}
      className={classnames('flex items-center focus:outline-none', {
        'text-md rounded-full p-2 sm:pr-6': onlyAvatar !== true,
        'bg-white text-black': isWhite === true && onlyAvatar !== true,
        'bg-gray-100 text-black': isWhite !== true && onlyAvatar !== true,
      })}
      {...rest}
    >
      <span className="sr-only">{t('navbar.sr-only.open-user-menu')}</span>

      <Avatar
        round
        fgColor={'white'}
        color={'black'}
        src={user.getAvatar(50)}
        name={user.getFullName()}
        size="35"
      />

      {onlyAvatar !== true && (
        <span className={'ml-3 hidden sm:block'}>{user.getHiddenLastName()}</span>
      )}
    </button>
  )
})

UserMenuButton.propTypes = {
  user: PropTypes.object,
  onlyAvatar: PropTypes.bool,
}

export default UserMenuButton
