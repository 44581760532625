import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Menu, MenuDivider } from '@szhsin/react-menu'
import classnames from 'classnames'

// Local Components
import UserMenuButton from './UserMenuButton'
import UserMenuLink from './UserMenuLink'
import { UserMenuLinkProfileLabel } from './labels'

// Utils
import useAuth from '../../state/auth/useAuth'
import ROUTES from '../../config/routes'

const UserMenu = ({ items, onlyAvatar, direction = 'bottom', isWhite }) => {
  const { t } = useTranslation('common')

  const { isGuest, user, logout } = useAuth()

  const [display] = useState('arrow')
  const [align] = useState('center')
  const [position] = useState('anchor')
  const [viewScroll] = useState('auto')

  if (isGuest) {
    return null
  }

  return (
    <div role={'menu'}>
      <Menu
        menuButton={<UserMenuButton user={user} onlyAvatar={onlyAvatar} isWhite={isWhite} />}
        menuClassName={classnames({
          'p-2 rounded-xl w-52 shadow-2xl bg-white ring-1 ring-black/5': true,
        })}
        key={direction}
        direction={direction}
        align={align}
        position={position}
        viewScroll={viewScroll}
        arrow={display === 'arrow'}
        offsetX={display === 'offset' && (direction === 'left' || direction === 'right') ? 12 : 0}
        offsetY={display === 'offset' && (direction === 'top' || direction === 'bottom') ? 12 : 0}
      >
        {items.map((item, idx) => {
          if (item.type === 'divider') {
            return <MenuDivider key={`divider-${idx}`} className={'bg-gray-100'} />
          }

          if (item.type === 'logout') {
            return (
              <UserMenuLink
                key={`signout-${idx}`}
                onClick={logout}
                label={t('navbar.userMenu.signOut')}
              />
            )
          }

          if (item.type === 'profile') {
            return (
              <UserMenuLink
                key={`profile-${idx}`}
                onClick={() => navigate(ROUTES.profile(user.username_slug))}
                label={<UserMenuLinkProfileLabel user={user} />}
              />
            )
          }

          return (
            <UserMenuLink
              key={`link-${idx}`}
              onClick={() => navigate(item.href)}
              label={item.label}
            />
          )
        })}
      </Menu>
    </div>
  )
}

UserMenu.propTypes = {
  onlyAvatar: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['logout', 'profile', 'divider']),
      href: PropTypes.string,
      label: PropTypes.any,
    }),
  ),
  direction: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  isWhite: PropTypes.bool,
}

export default UserMenu
